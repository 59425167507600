import { jwtDecode } from "jwt-decode";
import { removeUser, setAuthUser } from "../features/Auth Slice/authSlice";
import { create } from "apisauce";
import spinnerSvc from "../utils/spinner-service";
// import { INewPasswordRequest } from "../types/interfaces/INewPasswordRequest.interface";
import { ITokens } from "../types/interfaces/ITokens.interface";
import { ITokenVerfication } from "../types/interfaces/ITokenVerfication.interface";
import { ILoginCredentials } from "../types/interfaces/ILoginCredentials.interface";
import { BehaviorSubject, Subject } from "rxjs";
import { storageKeys } from "../constants/storage.constants";
import { store } from "../store/store";
import { api } from "../utils/axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, protectedResources } from "../config/auth-config";
import { msalInstance } from "..";


const isAuthenticatedSub = new BehaviorSubject(false);
const signedIn$ = new Subject();

export const authApi = create({
  baseURL: process.env.REACT_APP_API_URL_LOCAL,
  headers: { Accept: "application/json" },
});

authApi.axiosInstance.interceptors.request.use(
  async (config) => {
    spinnerSvc.start();
    return config;
  },
  (err) => console.error(err)
);
authApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
  }
);




export const signIn = async () => {
  try {
    const response: any = await api.post("user/signInWithMicrosoft");

    console.log(response);
    return response?.data;
  } catch (error) {
    return { err: error };
  }
};

export async function acquireAccessToken() {

  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    const account = accounts[0]
    msalInstance.setActiveAccount(account)
    try {
      const response = await msalInstance.acquireTokenSilent({
        account,
        scopes: protectedResources.apiService.scopes,
      })
      return response.accessToken
    } catch (err) {
      console.log(err);
      return null
    }
  } else {
    console.error('No accounts found')
    return null
  }
}
export const authLogin = async (loginRequest: ILoginCredentials) => {
  try {
    const response: any = await authApi.post("/user/signIn", loginRequest);

    if (response?.data) {
      const tokenBase64: any = response.data.access_token;
      const tokenData: any = jwtDecode(tokenBase64);
      if (localStorage.getItem(storageKeys?.rememberMe) === "true")
        localStorage.setItem(storageKeys?.user, JSON.stringify(tokenData));
      else localStorage.setItem(storageKeys?.user, JSON.stringify(tokenData));
      store.dispatch(setAuthUser(tokenData));
      storeTokens(response.data);
      // notificationSocket?.initializeConnection(
      //   tokenData?.id,
      //   tokenData?.designation
      // );
    }
    return response?.data;
  } catch (error) {
    return { err: error };
  }
};

export const getMyDetails = async ()=>{

  try{
    const res : any = await api.get('/user/me');
    if(res.data){
      localStorage.setItem(storageKeys?.user, JSON.stringify(res.data.user));
      return res?.data?.user;
    }
  }
  catch{
console.log("error")
  }
}

export const signOut = async()=>{
  try{
    const res : any = await api.post('/user/signOut');
    store.dispatch(removeUser());

  }
  catch{
console.log("error")
  }
}

export const getCurrency = async () => {
  try {
    const response = await api.get(`/settings`);

    return response?.data;
  } catch (error) {
    return { err: error };
  }
};



export const refreshToken = async () => {
  const rtToken =
    localStorage.getItem(storageKeys?.rememberMe) === "true"
      ? localStorage.getItem(storageKeys?.rfToken)
      : localStorage.getItem(storageKeys?.rfToken);
return rtToken;
};
//user authApi in forget password instead of "api" service
export const resetPassword = async (email: string) => {
  try {
    const response = await authApi.post("/admin/forgetPassword", {
      email: email,
    });
    if (response && response.ok) {
      return { mailSent: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (error) {
    return { err: error };
  }
};

export const verifyResetPassword = async (params: ITokenVerfication) => {
  try {
    const response = await authApi.post(`/admin/verifyResetToken`, params);
    if (response && response?.ok) {
      return { verified: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (error) {
    return { err: error };
  }
};

// export const changePassword = async (params: INewPasswordRequest) => {
//   try {
//     const response = await authApi.put(`/admin/changePassword`, params);
//     if (response && response?.ok) {
//       return { changedSuccess: true };
//     } else {
//       throw new Error("There is an error in password change");
//     }
//   } catch (error) {
//     return { err: error };
//   }
// };



export const storeTokens = (tokens: ITokens) => {
  const { refresh_token, access_token } = tokens;

  if (localStorage.getItem(storageKeys?.rememberMe) === "true") {
    localStorage.setItem(storageKeys?.acToken, access_token);
    localStorage.setItem(storageKeys?.rfToken, refresh_token);
  } else {
    localStorage.setItem(storageKeys?.acToken, access_token);
    localStorage.setItem(storageKeys?.rfToken, refresh_token);
  }
};

export const isAuthenticated = () => {
  return isAuthenticatedSub.asObservable();
};

export const setIsAuthenticated = (isAuthenticated: boolean) => {
  isAuthenticatedSub.next(isAuthenticated);
};



